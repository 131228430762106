.TypeAheadSearch .Items {
    opacity: 0;
    position: absolute;
    background-color: #eef;
    border: 1px solid #ccc;
    z-index: 9999;

    transition: opacity 1s;
}

.TypeAheadSearch .Items.showItems {
    display: block;
    opacity: 1;

    transition: opacity 1s;
}

.TypeAheadSearch .Items.hideItems {
    display: none;
}
