#projectMenu {
    background-color: #cacad4;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;

    border-radius: 10px;

    z-index: 1000;
}

#projectMenu a i.bi {
    font-size: 2rem;
}

#projectMenu .btn i.bi {
    font-size: 1.5rem;
}

#projectMenu a {
    color: #0011CE;
    padding-right: 10px;
    font-size: 20px;
}

#projectMenu a:hover {
    color: black;
}

#projectMenu a.btn {
    color: #FFFFFF;
    font-size: 16px;
}

#projectMenu .btn-success {
    margin-right: 10px;
}
