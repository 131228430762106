.googleMap {
    height: 80vh;
}

/* Search bar */
#pac-input {
    font-size: 25px;
    font-weight: 200;
    text-overflow: ellipsis;
    width: 90vw;
    max-width: 1036px;
    margin-top: 65px;
}
