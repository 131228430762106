.dropZone {
    background-color: #aec4e8;
    padding: 30px;
    border-radius: 10px;
    margin: 10px;
}

.dropZone:hover {
    background-color: #d5dfee;
    padding: 30px;
    border-radius: 10px;
    margin: 10px;
}

.dropZone > div {
    margin-bottom: 10px;
}
