.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

.north {
    background-color: #abafd0;
    width: 100vw;
    height: 5vh;
}

.middle {
    background-color: #FFF;
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle.displayMobileMenu {
    height: 10vh;
}

.south {
    background-color: #abafd0;
    width: 100vw;
    height: 5vh;
    position: fixed;
    bottom: 0px;
}

.south.displayMobileMenu {
    background-color: #abafd0;
    height: 85vh;
}

.fullscreen .btn-close {
    margin-right: 1em;
}

.fullscreen .middle canvas {
    max-width: 90vw;
    max-height: 90vh;
    width: 100%;
    height: 100%;
}

.no-scroll {
    overflow: hidden;
}

.select-check-box {
    margin-left: 10px;
    transform: scale(2);
}

.spinner-border {
    color: green;
    width: 10vh;
    height: 10vh;
    margin-left: 10px;
    margin-top: 10px;
}

.colorPicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.errors {
    width: 50%;
    margin: 0 auto;
}
