html, body {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App {
    flex: 1;
}

.autocomplete-dropdown-container {
    position: absolute;
}

tr.disabled, tr.disabled td {
    background-color: #252525;
    color: white !important;
}

.bottom-spacing {
    margin-bottom: 15px;
}

.icon {
    max-width: 18px;
    margin-right: 5px;
}

.sticky-top {
    position: sticky;
    top: 0px;
}

.row .sticky-top > * {
    width: 100%;
}

.edit-section {
    background-color: #b1bbe0;
    padding-bottom: 10px;
}

.roundCard {
    border: 2px solid #0011CE;
    border-radius: 10px;
    margin-bottom: 5px;
}

/* Checkmark in input */
.bi-check-circle {
    position: absolute;
    padding-left: 5px;
    padding-top: 5px;
}

.bi-check-circle-checkbox {
    position: absolute;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: -25px;
}

.bi-check-circle-list {
    position: absolute;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: -10px;
}

.input-text-padding-left {
    padding-left: 25px;
}

.menuIcon {
    color: #0011CE;
    padding-right: 10px;
}

.scrollable-section {
    padding-right: 20px;
}

.scrollable-section-inside {
    max-height: 85vh;
    overflow: scroll;
}

/* Better visibility */
.form-check-input[type=checkbox], .form-check-input[type=radio] {
    border: 2px solid #0011CE;
}
